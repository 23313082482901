<template>
<div>
  <h1>Statistics</h1>
  <h2>Page visits</h2>
  <PageVisitChart v-if="!isLoading" :statistics="statistics"/>
</div>
</template>
<script>
import PageVisitChart from "@/components/admin/users/PageVisitChart.vue";
export default {
  components: {
    PageVisitChart
  },
  data() {
    return {
      isLoading: false
    };
  },
  async created() {
    this.isLoading = true
    await this.fetchAll();
    this.isLoading = false
  },
  computed: {
    statistics() {
      return this.$store.state.Statistics.all;
    },
  },
  methods: {
    async fetchAll() {
      await this.$store.dispatch("Statistics/fetchAll");
    }
  },
};
</script>